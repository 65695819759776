/* CustomerNav.css */

.custom-sider {
  position: fixed;
  left: 0;
  top: 0;
  width: 250px; /* Adjust based on your sidebar width */
  height: 100vh;
  transition: width 0.2s;
  background-color: #001529; /* Default dark background */
  overflow: hidden; /* Prevent content overflow */
}

.custom-sider.settings-menu {
  background-color: #ffffff; /* White background for settings menu */
}

.nav-header {
  display: flex;
  align-items: center;
  padding: 0 16px;
  height: 64px; /* Match header height */
  cursor: pointer;
}

.customer-avatar {
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.customer-name {
  margin-left: 16px;
  color: white;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: calc(100% - 64px);
}

.custom-sider.settings-menu .customer-name {
  color: #000; /* Dark text in settings menu */
}

.nav-divider {
  margin: 0;
  border-top: 1px solid rgba(255, 255, 255, 0.2); /* Light divider on dark background */
}

.custom-sider.settings-menu .nav-divider {
  border-top: 1px solid rgba(0, 0, 0, 0.1); /* Dark divider on light background */
}

.menu-container {
  /* Adjust height to account for the header and bottom menu */
  position: absolute;
  top: 64px; /* Height of the nav-header */
  bottom: 0;
  left: 0;
  right: 0;
  overflow-y: auto;
  padding-bottom: 120px; /* Height of bottom menu plus some spacing */
}

.main-menu .ant-menu,
.settings-menu .ant-menu {
  background-color: inherit;
  border-right: none;
}

.custom-sider .ant-menu-item,
.custom-sider .ant-menu-submenu-title {
  display: flex;
  align-items: center;
  padding-left: 24px;
}

.custom-sider .ant-menu-item .ant-menu-item-icon,
.custom-sider .ant-menu-submenu-title .ant-menu-item-icon {
  min-width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-sider .ant-menu-item .ant-menu-title-content,
.custom-sider .ant-menu-submenu-title .ant-menu-title-content {
  transition: opacity 0.2s ease, width 0.2s ease, margin-left 0.2s ease;
  overflow: hidden;
  white-space: nowrap;
  opacity: 1;
  width: auto;
  margin-left: 16px;
}

.custom-sider.collapsed .ant-menu-item .ant-menu-title-content,
.custom-sider.collapsed .ant-menu-submenu-title .ant-menu-title-content {
  opacity: 0;
  width: 0;
  margin-left: 0;
}

.custom-sider .ant-menu-item .anticon,
.custom-sider .ant-menu-submenu-title .anticon {
  font-size: 18px;
  color: inherit;
}

.custom-sider.settings-menu .ant-menu-item .anticon,
.custom-sider.settings-menu .ant-menu-submenu-title .anticon {
  color: inherit;
}

.custom-sider .ant-menu-submenu-title {
  padding-right: 40px;
  position: relative;
}

.custom-sider .ant-menu-submenu-arrow {
  position: absolute;
  right: 16px;
}

.custom-sider.collapsed .ant-menu-submenu-title {
  padding-right: 24px;
}

.custom-sider.collapsed .ant-menu-submenu-arrow {
  display: none;
}

.custom-sider .ant-menu-item:hover,
.custom-sider .ant-menu-submenu-title:hover {
  background-color: #1890ff !important;
  color: #fff !important;
}

.custom-sider.settings-menu .ant-menu-item:hover,
.custom-sider.settings-menu .ant-menu-submenu-title:hover {
  background-color: #e6f7ff !important;
  color: #1890ff !important;
}

.custom-sider .ant-menu-item-selected {
  background-color: #1890ff !important;
  color: #fff !important;
}

.custom-sider.settings-menu .ant-menu-item-selected {
  background-color: #e6f7ff !important;
  color: #1890ff !important;
}

/* Bottom menu styles */
.bottom-menu {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #001529; 
}

.settings-menu {
  background-color: #ffffff; /* White background in settings menu */
}

.footer-items,
.user-menu {
  display: flex;
  flex-direction: column;
}

.footer-item,
.user-menu-item {
  display: flex;
  align-items: center;
  padding-left: 24px;
  height: 48px;
  cursor: pointer;
  color: white;
  transition: background-color 0.3s;
}

.footer-item-icon,
.user-menu-item .ant-avatar {
  min-width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-item-label,
.user-name {
  margin-left: 16px;
  color: inherit;
  transition: opacity 0.2s ease, width 0.2s ease, margin-left 0.2s ease;
  white-space: nowrap;
  overflow: hidden;
}

.custom-sider.collapsed .footer-item-label,
.custom-sider.collapsed .user-name {
  opacity: 0;
  width: 0;
  margin-left: 0;
}

.footer-item:hover,
.user-menu-item:hover {
  background-color: #1890ff;
  color: #fff;
}

.footer-item.settings-menu,
.user-menu.settings-menu .user-name {
  color: #000; /* Dark text in settings menu */
}

.footer-item.settings-menu:hover,
.user-menu-item.settings-menu:hover {
  background-color: #e6f7ff;
  color: #1890ff;
}

.custom-sider.settings-menu .footer-item,
.custom-sider.settings-menu .user-menu-item {
  color: #000;
}

.custom-sider.collapsed .footer-item,
.custom-sider.collapsed .user-menu-item {
  justify-content: center;
  padding-left: 0;
}

.custom-sider .ant-menu {
  background-color: inherit;
  border-right: none;
}

.custom-sider .ant-menu-item {
  transition: all 0.3s ease;
  color: white;
}

.custom-sider.settings-menu .ant-menu-item {
  color: #000; /* Dark text in settings menu */
}
