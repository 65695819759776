/* frontend/src/components/customer/customerHeader.css */

.customer-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  background-color: #fff;
  height: 64px;
}

.header-left {
  display: flex;
  align-items: center;
}

.header-right {
  display: flex;
  align-items: center;
}

.back-button {
  margin-right: 8px;
}

.header-title {
  margin: 0;
  font-size: 1.5em;
}

.search-input {
  width: 200px;
}

.search-button,
.filter-button,
.secondary-action-button {
  margin-left: 8px;
}

.primary-action-button {
  margin-left: 8px;
}