/* frontend/src/components/customer/CustomerLayout.css */

.customer-inner-layout {
    transition: margin-left 0.2s;
  }
  
  .layout-header {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 999;
    background: #fff;
  }
  
  .customer-content {
    overflow-y: auto;
    background: #f0f2f5;
    padding-left: 24px;
    padding-top: 12px;
  }
  