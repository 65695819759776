/* frontend/src/App.css */

/* Add any custom global styles here */

body {
    margin: 0;
    padding: 0;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }
  
  .logo {
    color: white;
    text-align: center;
    padding: 16px;
    font-size: 18px;
    font-weight: bold;
  }
  